import { Currency, CurrencyAmount } from '@sushiswap/core-sdk'
import { BigNumber as BigNumberJS } from 'bignumber.js'
import numbro from 'numbro'

const DEFAULT_PRECISION = 2

interface NumberOptions extends numbro.Format {
  showExact?: boolean
}

export const format = (value: number | string | BigNumberJS, numbroOptions: NumberOptions = {}): string => {
  if (value === undefined || value === null) {
    throw Error('value is not valid')
  }
  let v = value
  if (value === '') {
    v = 0
  }
  if (value instanceof BigNumberJS) {
    if (value.isNaN()) {
      return '-'
    }
    v = value.toFixed()
  }
  const precision = new BigNumberJS('1e-' + (numbroOptions.mantissa ? numbroOptions.mantissa : DEFAULT_PRECISION))
  const bn = new BigNumberJS(v)
  const showExact = numbroOptions.showExact
  delete numbroOptions.showExact

  return !showExact && bn.gt(0) && bn.lt(precision)
    ? '< ' + precision.toFixed()
    : numbro(v)
        .format({
          thousandSeparated: true,
          mantissa: DEFAULT_PRECISION,
          optionalMantissa: false,
          trimMantissa: true,
          average: false,
          ...numbroOptions,
        })
        .toUpperCase()
}

export const currencyAmountToNumber = (currencyAmount: CurrencyAmount<Currency>): number => {
  return currencyAmountToBigNumberJS(currencyAmount).toNumber()
}

export const currencyAmountToString = (currencyAmount: CurrencyAmount<Currency>): string => {
  return currencyAmountToBigNumberJS(currencyAmount).toString()
}

export const currencyAmountToBigNumberJS = (currencyAmount: CurrencyAmount<Currency> | undefined): BigNumberJS => {
  if (currencyAmount === undefined) {
    return new BigNumberJS(0)
  }

  try {
    return new BigNumberJS(currencyAmount.quotient.toString()).div(
      new BigNumberJS(10).pow(currencyAmount.currency.decimals)
    )
  } catch (e) {
    return new BigNumberJS(0)
  }
}
