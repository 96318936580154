import { ChainId, Token } from '@sushiswap/core-sdk'
import config from 'config'

import * as BSC_TOKENS from './bsc'
import * as FANTOM_TOKENS from './fantom'

export const GET_AVAR_TOKEN = (chainId?: ChainId) => {
  if (!chainId) {
    return TOKENS[config.defaultChainId]
  }
  return TOKENS[chainId] ?? TOKENS[config.defaultChainId]
}

declare type AddressMap = {
  [chainId: number]: { [address: string]: Token }
}

const TOKENS: AddressMap = {
  [ChainId.BSC]: {
    [BSC_TOKENS.SCULPT.address]: BSC_TOKENS.SCULPT,
    [BSC_TOKENS.SCULPTETH.address]: BSC_TOKENS.SCULPTETH,
    // BNB
    '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c': BSC_TOKENS.WRAPPED_NATIVE,
    '0xD2ff91d7e14A76823F60B74f65D6F4280E7DAC3c': new Token(
      ChainId.BSC,
      '0xD2ff91d7e14A76823F60B74f65D6F4280E7DAC3c', //xy
      18,
      'sBNB', //xy
      'Sculptor BNB' //xy
    ),
    '0xca5b152982DdAD3C22904bfFBa97A485CD4c7573': new Token(
      ChainId.BSC,
      '0xca5b152982DdAD3C22904bfFBa97A485CD4c7573', //xy
      18,
      'vBNB', //xy
      'Sculpt Variable Debt BNB Token' //xy
    ),
    // CAKE
    '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82': new Token(
      ChainId.BSC,
      '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
      18,
      'CAKE',
      'PancakeSwap Token'
    ),
    '0xE37c6B98d0e8ab6ECFF65346609f1cF098EdAa06': new Token(
      ChainId.BSC,
      '0xE37c6B98d0e8ab6ECFF65346609f1cF098EdAa06', //xy
      18,
      'sCAKE', //xy
      'Sculptor CAKE' //xy
    ),
    '0x7e8f5cb5961738E7163376a38b2e63E27eC57408': new Token(
      ChainId.BSC,
      '0x7e8f5cb5961738E7163376a38b2e63E27eC57408', //xy
      18,
      'vCAKE', //xy
      'Sculpt Variable Debt CAKE Token' //xy
    ),
    // BUSD
    '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56': new Token(
      ChainId.BSC,
      '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      18,
      'BUSD',
      'Binance-Peg BUSD Token'
    ),
    '0xE7BabBD879AC34C96955cB882ee27e8A64C4Af93': new Token(
      ChainId.BSC,
      '0xE7BabBD879AC34C96955cB882ee27e8A64C4Af93', //xy
      18,
      'sBUSD', //xy
      'Sculptor BUSD' //xy
    ),
    '0x55544Bc14760d4AB9E0Db86efFc2C9d3f23bc9D9': new Token(
      ChainId.BSC,
      '0x55544Bc14760d4AB9E0Db86efFc2C9d3f23bc9D9', //xy
      18,
      'vBUSD', //xy
      'Sculpt Variable Debt BUSD Token' //xy
    ),
    // DAI
    '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3': new Token(
      ChainId.BSC,
      '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
      18,
      'DAI',
      'Binance-Peg Dai Token'
    ),
    '0xF2636e8EA3d3b02135D8973d8D6FaDe86d940863': new Token(
      ChainId.BSC,
      '0xF2636e8EA3d3b02135D8973d8D6FaDe86d940863', //xy
      18,
      'sDAI', //xy
      'Sculptor DAI' //xy
    ),
    '0xF870F44cFC55720C02903Ba0327066d7e2afCCD5': new Token(
      ChainId.BSC,
      '0xF870F44cFC55720C02903Ba0327066d7e2afCCD5', //xy
      18,
      'vDAI', //xy
      'Sculpt Variable Debt DAI Token' //xy
    ),
    // USDT
    '0x55d398326f99059fF775485246999027B3197955': new Token(
      ChainId.BSC,
      '0x55d398326f99059fF775485246999027B3197955',
      18,
      'USDT',
      'Binance-Peg BSC-USD'
    ),
    '0x2a697f25a78beb7a98Cc241e132520e9547753e7': new Token(
      ChainId.BSC,
      '0x2a697f25a78beb7a98Cc241e132520e9547753e7', //xy
      18,
      'sUSDT', //xy
      'Sculptor USDT' //xy
    ),
    '0x410D2a98a4df15Db633cD60bA1953BF3786BB38F': new Token(
      ChainId.BSC,
      '0x410D2a98a4df15Db633cD60bA1953BF3786BB38F', //xy
      18,
      'vUSDT', //xy
      'Sculpt Variable Debt USDT Token' //xy
    ),
    // USDC
    '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d': new Token(
      ChainId.BSC,
      '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
      18,
      'USDC',
      'Binance-Peg USD Coin'
    ),
    '0xA9D966664c0ff9BB0BC55b7dC42fd368ec916359': new Token(
      ChainId.BSC,
      '0xA9D966664c0ff9BB0BC55b7dC42fd368ec916359', //xy
      18,
      'sUSDC', //xy
      'Sculptor USDC' //xy
    ),
    '0x7a57Da4F3D98e14d799FBDe4e295946Fd4d42EEc': new Token(
      ChainId.BSC,
      '0x7a57Da4F3D98e14d799FBDe4e295946Fd4d42EEc', //xy
      18,
      'vUSDC', //xy
      'Sculpt Variable Debt USDC Token' //xy
    ),
    // ETH
    '0x2170Ed0880ac9A755fd29B2688956BD959F933F8': new Token(
      ChainId.BSC,
      '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
      18,
      'ETH',
      'Binance-Peg Ethereum Token'
    ),
    '0x2eB3fed3C74509975C39d353dC2ebf9F52443A7B': new Token(
      ChainId.BSC,
      '0x2eB3fed3C74509975C39d353dC2ebf9F52443A7B', //xy
      18,
      'sETH', //xy
      'Sculptor ETH' //xy
    ),
    '0xCF7923629D8B77491743a48a4D329EB8ACEC2c2f': new Token(
      ChainId.BSC,
      '0xCF7923629D8B77491743a48a4D329EB8ACEC2c2f', //xy
      18,
      'vETH', //xy
      'Sculpt Variable Debt ETH Token' //xy
    ),
    // BTCB
    '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c': new Token(
      ChainId.BSC,
      '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
      18,
      'BTCB',
      'Binance-Peg BTCB Token'
    ),
    '0x4eBD204A7A1931D2d298C7f37437E6413E4c41D5': new Token(
      ChainId.BSC,
      '0x4eBD204A7A1931D2d298C7f37437E6413E4c41D5', //xy
      18,
      'sBTCB', //xy
      'Sculptor BTCB' //xy
    ),
    '0x6631b36a3fD44FCC387fD61c1473FC97e3F759A6': new Token(
      ChainId.BSC,
      '0x6631b36a3fD44FCC387fD61c1473FC97e3F759A6', //xy
      18,
      'vBTCB', //xy
      'Sculpt Variable Debt BTCB Token' //xy
    ),
  },
  [ChainId.FANTOM]: {
    [FANTOM_TOKENS.SCULPT.address]: FANTOM_TOKENS.SCULPT,
    [FANTOM_TOKENS.SCULPTETH.address]: FANTOM_TOKENS.SCULPTETH,
    '0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E': new Token(
      ChainId.FANTOM,
      '0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E',
      18,
      'DAI',
      'Dai Stablecoin'
    ),
    '0xFe0341D274608CFE099eEC03DFebBE88EDD27e4e': new Token(
      ChainId.FANTOM,
      '0xFe0341D274608CFE099eEC03DFebBE88EDD27e4e',
      18,
      'sDAI',
      'Sculptor DAI'
    ),
    '0x724E2db7C2e41707EdD5A7f4bCBB4f4d8Aa104Eb': new Token(
      ChainId.FANTOM,
      '0x724E2db7C2e41707EdD5A7f4bCBB4f4d8Aa104Eb',
      18,
      'vDAI',
      'Sculpt Variable Debt Token DAI'
    ),

    '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83': FANTOM_TOKENS.WRAPPED_NATIVE,
    '0x9B107d0FD81304bA33F78e393b96d3A2D4538188': new Token(
      ChainId.FANTOM,
      '0x9B107d0FD81304bA33F78e393b96d3A2D4538188',
      18,
      'sFTM',
      'Sculptor FTM'
    ),
    '0xa945B9E04FDDE5Bc89F134afF384E00Fb5F1c754': new Token(
      ChainId.FANTOM,
      '0xa945B9E04FDDE5Bc89F134afF384E00Fb5F1c754',
      18,
      'vFTM',
      'Sculpt Variable Debt Token FTM'
    ),

    '0x04068DA6C83AFCFA0e13ba15A6696662335D5B75': new Token(
      ChainId.FANTOM,
      '0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
      6,
      'USDC',
      'USD Coin'
    ),
    '0x111828513302a59f8Bbde3fA251dA934EB9Af699': new Token(
      ChainId.FANTOM,
      '0x111828513302a59f8Bbde3fA251dA934EB9Af699',
      6,
      'sUSDC',
      'Sculptor USDC'
    ),
    '0x15AA6899ea37De6CE9510b5741b2DF5e22A22c86': new Token(
      ChainId.FANTOM,
      '0x15AA6899ea37De6CE9510b5741b2DF5e22A22c86',
      6,
      'vUSDC',
      'Sculpt Variable Debt Token USDC'
    ),

    '0x049d68029688eAbF473097a2fC38ef61633A3C7A': new Token(
      ChainId.FANTOM,
      '0x049d68029688eAbF473097a2fC38ef61633A3C7A',
      6,
      'USDT',
      'Frapped USDT'
    ),
    '0x202096ced5dB6c58a3Fe0e02111Da126f8F8eba2': new Token(
      ChainId.FANTOM,
      '0x202096ced5dB6c58a3Fe0e02111Da126f8F8eba2',
      6,
      'sUSDT',
      'Sculptor USDT'
    ),
    '0xA0d41DF7FF3ED54d0C05038eb5ADEa907E1cAbA5': new Token(
      ChainId.FANTOM,
      '0xA0d41DF7FF3ED54d0C05038eb5ADEa907E1cAbA5',
      6,
      'vUSDT',
      'Sculpt Variable Debt Token USDT '
    ),
  },
}
